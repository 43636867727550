<template>
    <default-app-template>
        <div class="video mx-auto w-4/5 mb-10">
            <div
                class="player-container"
                data-cypress="patient.explanation.video"
            >
                <vue-core-video-player
                    :autoplay="false"
                    :src="video.url"
                ></vue-core-video-player>
            </div>
        </div>
        <div class="flex justify-end  mx-auto w-4/5">
            <basic-button
                icon="check"
                icon-right
                data-cypress="patient.explanation.submit"
                @click.prevent="next()"
                >Start Measurement</basic-button
            >
        </div>
    </default-app-template>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            video: {
                url: process.env.VUE_APP_EXPLANATION_VIDEO,
            },
        }
    },
    computed: {
        ...mapState('measurement', {
            measurement: state => state.active,
        }),
    },

    mounted() {},
    methods: {
        next() {
            this.increaseStep()
            // Go to Named Route
            this.$router.push({
                name: 'PerformMeasurement',
                params: { uuid: this.measurement.uuid },
            })
        },

        // VUEX
        ...mapActions('measurement', ['increaseStep', 'decreaseStep']),
    },
}
</script>
